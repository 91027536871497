import {
  Map,
  View
} from 'ol';
import { defaults as defaultControls, ScaleLine } from 'ol/control';

import { registerProj } from './projection';


/**
 * 初始化地图
 * @param  {string} mapId 地图容器的DOM id
 * @param { Object } params 视图的配置参数
 * @returns { Map } this is a ol.map
 */
export function initMap(mapId, params) {
  document.getElementById(mapId).innerHTML = ""; // 清空地图容器
  const options = params || {};

  registerProj();

  // 配置视图
  options.center = options.center || [0, 0];
  options.zoom = options.zoom || 0;

  return new Map({
    target: mapId, //地图容器
    layers: [],  // 图层
    view: new View(options),
    controls: defaultControls({ //默认空间
      attribution: false,  //属性
      zoom: false,  // 放大缩小空间
      rotate: false  //选装空间
    }).extend([/*new ScaleLine()*/]) // 比例尺
  });
}

/**
 * 地图点击事件时，获取鼠标的地理坐标和像素坐标信息
 * 使用说明
 * eg map.on("click",function(e){
 *    let mousePosition =  getMapMousePosition(e);
 *    // 根据像素捕捉要素
 *    let features =map.getFeaturesAtPixel(mousePosition.mousePixel);
 *    // 根据像素判断是否有要素
 *    let bool = map.hasFeatureAtPixel(mousePosition.mousePixel);
 *    // 根据像素处理图层
 *    forEachLayerAtPixel(mousePosition.mousePixel,function(layer){
 *    })
 *    // 根据地理坐标指定弹出框overlay的位置
 *    overlay.setPosition(mousePosition.mouseCoordinate);
 * })
 * @param  { ol.MapBrowserEvent } e
 * @returns { ( null | {mouseCoordinate:ol.coordinate,mousePixel:ol.Pixel}) }
 *
 */
export function getMapMousePosition(e) {
  let map = e.map;
  if (map.mapClickEnable && map.mapClickEnable == false) {
    return null;
  }

  let mouseCoordinate = e.coordinate;
  let mousePixel = e.pixel;
  return { mouseCoordinate: mouseCoordinate, mousePixel: mousePixel };
}
/**
 * 根据key-value找到图层
 * @param  { ol.Map} map
 * @param  { String } key
 * @param  { } value
 * @returns { ([]|Array<ol.layer>)}
 */
export function getLayersByKVP(map, key, value) {
  let layers = map.getLayers().getArray();
  let resultLayers = [];
  for (let i = 0; i < layers.length; i++) {
    const layer = layers[i];
    if (layer[key] == value) {
      resultLayers.push(layer);
    }
  }
  return resultLayers;
}

/**
 * 根据key-value移除图层
 * @param  { ol.Map} map
 * @param  { String } key
 * @param  { } value
 */
export function removeLayersByKVP(map, key, value) {
  let layers = map.getLayers().getArray();
  for (let i = layers.length - 1; i >= 0; i--) {
    let layer = layers[i];
    if (layer[key] && layer[key] == value) {
      map.removeLayer(layer);
    }
  }
}
