/**
 * 底图业务
 */

import { removeLayersByKVP } from '../map';
import { createImageLayer } from '../layer';

export default function loadBaseLayer(map, baseLayers) {
  //console.log("loadBaseLayer切换地图",baseLayers);
  // 底图加载，需判断是否已加载其他底图，如有则移除
  removeLayersByKVP(map, 'classType', 'base');
  for (let i = 0; i < baseLayers.length; i += 1) {
    addLayerOnMap(map, baseLayers[i], i);
  }
}

async function addLayerOnMap(map, data, index) {
  const layer = await createImageLayer(data);
  if (layer) {
    layer.classType = 'base';
    // window.hgt.map.addLayer(layer); // 一般图层的加载
    map.getLayers().insertAt(index, layer); // 特殊图层的加载，底图最下 0，
  }
}
