import Feature from 'ol/Feature';
import {
  Point, LineString, Polygon, Circle,
} from 'ol/geom';

/**
 * 根据json 对象生成一个要素对象
 * @param  { Object } param
 * @param  { string } idkey = 'id'
 * param = {
 *   type : "point" | "lineString" | "polygon" | "circle"
 *   coordinates: [x,y] | [[x,y],[x1,y1]] | [[[x,y],[x1,y1],[x,y]],[]] ,
 *   center: [x,y], // 针对 circle
 *   radius : number  eg.5 针对 circle
 * }
 * @returns { null | ol.Feature } Feature
 */
export function JsonObjectFeature(param, idkey = 'id') {
  const option = { ...param } || {};

  const type = option.featureType;
  delete option.featureType;

  if (!type) {
    return null;
  }

  let geometry = null;
  if (type === 'point' && option.coordinates) {
    geometry = new Point(option.coordinates);
    delete option.coordinates;
  } else if (type === 'lineString' && option.coordinates) {
    geometry = new LineString(option.coordinates);
    delete option.coordinates;
  } else if (type === 'polygon' && option.coordinates) {
    geometry = new Polygon(option.coordinates);
    delete option.coordinates;
  } else if (type === 'circle' && option.center && option.radius) {
    geometry = new Circle(option.center, option.radius);
    delete option.center;
    delete option.radius;
  }

  if (!geometry) {
    return null;
  }

  const feature = new Feature({
    geometry,
  });

  // 偏于根据id查找
  if (idkey && option[idkey]) {
    feature.setId(option[idkey]);
  }

  // 将其他参数设置为要素的属性信息
  feature.setProperties(option);
  return feature;
}

/**
 * 根据json数组 生成多个要素对象
 * @param  { Array<Object> } params
 *
 * @returns { [] | Array<ol/Feature> } Features
 */
export function JsonArrayFeatures(params) {
  const array = Array.isArray(params) ? params : [];

  const features = [];
  for (let i = 0; i < array.length; i += 1) {
    const element = array[i];
    const feature = JsonObjectFeature(element);

    if (feature) {
      features.push(feature);
    }
  }
  return features;
}
